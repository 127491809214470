@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


/* ====================================================
    Global
   ==================================================== */



body {
	font-size: 16px;
	line-height: 1.1em;
}

p, li {
	line-height: 1.2em;
}

li {
	margin-bottom: 1em;
}

ul ul {
	list-style-type: circle;
}

h1 {
	color: $primary-color;
	line-height: 1.1em;
	// font-size: 30px;
	margin-bottom: 0px;

	span{
		color: $secondary-color;
	}
}

h2 {
	color: $primary-color;
	line-height: 1.1em;
	// font-size: 24px;
}
h3{
	color: $primary-color;
	line-height: 1.1em;
}
h4{
	color: $secondary-color;
	margin-bottom:0;
	line-height: 1.1em;

	&.dark{
		color:inherit;
		margin-bottom: 15px;
	}
}
h5{
	color: $secondary-color;
}
h6{
	color: $primary-color;
	line-height: 1.2em;
	font-family:inherit;
}

a {
	color: $secondary-color;
	text-decoration: underline;
	-webkit-tap-highlight-color: transparent;

	&:hover{
		color: $primary-color;
	}
}

ul {
	color: $secondary-color;

	span {
		color: $black;
	}
}

sup {
	line-height:0;
	top: -0.4em;
	font-size:60%;
}

strong {
	font-weight: 700;
}

small {
	line-height: 0.5em;
}

.bold,
.strong {
	font-weight: 700;
}
.alter-font {
	font-size: 15px;
}

.container-spacing {
	padding-top: 50px;
	padding-bottom: 50px;
}


.footnote{
	font-size: 14px;

	p{
		text-indent: -5px;
		margin-left: 5px;
		margin-bottom: 10px;
	}
}

.ul-sub {
	margin-top: 1em;
	p.p-text {
		color: $black;
		font-weight: normal;
	}
}

.vAlign .cell{
	margin-top: auto;
	margin-bottom: auto;
}

.mBottom0{
	margin-bottom: 0;
}

.site_map {
	li h3 a {
		color: $primary-color;
		text-decoration: none;
	}

	li a {
		color:#000;
	}
}


.nolinkline {
	text-decoration: none;
}


.title-trans {
    display:none;
}

.alternate-row{
	background-color: #F5F5F5;
	border-top: 1px solid #D7D7D7;
	border-bottom: 1px solid #D7D7D7;
}

.backtotop{
	text-align: right;
	font-size:13px;
}

.bold-title{
	font-size: 18px;
	font-weight: 700;
}

/* ====================================================
    Header - desktop
   ==================================================== */
header {
	background-color: rgba(0,69,128, 0.75);
	position: absolute;
	top: 0;
	right:0;
	left:0;
	z-index: 10;
	padding-top: 9px;
	padding-bottom: 10px;
	max-width: 100%;
	margin: 0 auto;
	padding-right: 0;
    padding-left: 0;
	ul {
		list-style:none;
		color: #fff;
		margin:0;

		li{
			display: inline-block;
			margin-bottom:0;

			a {
				color: #fff;
				text-decoration: none;

				&:focus{
                    color:#fff;
                }

				&:hover{
					color: #E4B600;
				}
			}

		}
	}

	.top-nav-area {
		font-size: 14px;

		// p{
		// 	margin-bottom:5px;
		// }

		.top-nav {
			color: #fff;

			.menu {
				text-align: right;
				-webkit-box-pack: end;
				-webkit-justify-content: flex-end;
				-ms-flex-pack: end;
				justify-content: flex-end;

			}

			.menu a, .menu .button{
				padding: 0 5px;

				&::after {
					content:"|";
					margin-left: 9px;

				}
			}
			.menu li:last-child a::after{
				content: none;
				margin-left:0;
			}		
		}
	}
	
	.menu {
		.menu-text{
			padding-top: 0;
			padding-bottom: 0;
		}
	}


	.main-nav {
		padding-top:7px;

		ul {
			text-align: center;
			// font-size: 18px;
			text-transform: uppercase;
			line-height: 1.1em;	
			float: right;
			//float: left;

			li a{

                &:hover {
                    color:#E4B600;
                }
                &:focus{
                    color:#E4B600;
                }
                &.active{
                    color:#E4B600;
                }
             }
             li:hover > a {
                    color:#E4B600;
                }	
		}

		.is-dropdown-submenu{
			border:none;
			background-color: rgba($primary-color,0.85);
			font-size:16px;
			text-transform: capitalize;
			li{
				border-bottom: 1px solid #fff;
				&:last-child {
					border-bottom: none;
				}
			}
		}

		.dropdown.menu > li > a, .dropdown.menu > li.is-dropdown-submenu-parent > a{
			//padding: 0.7rem 2.5rem;
			padding: 0.7rem 2.8rem;
		}

		.dropdown.menu > li.opens-right > .is-dropdown-submenu{
			//top: 125%;
			top: 117%;
			
		}

		.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
			display:none;
		}

		.dropdown.menu > li.is-active > a {
			color:#69B8BF;
		}

		.is-dropdown-submenu.first-sub > li {
			width: auto;
			display: inline-block;
			border-bottom: 0;
		}

	}

	.mega-menu-container {
		ul {
			li a{

                &:hover {
                    color:#E4B600;
                }
                &:focus{
                    color:#E4B600;
                }
                &.active{
                    color:#E4B600;
                }
             }
             li:hover > a {
                    color:#E4B600;
                }	
		}
	}
	

}

header > .grid-container:first-child {

    @media screen and (min-width: 1024px) and (max-width: 1166px) {
    	padding-right: 0.225rem;
    	padding-left: 0.225rem;
    }
    .main-nav-area {
    	ul {
    		@media screen and (min-width: 1024px) and (max-width: 1166px) {
		    	float: right;
		    }
    	}
    }
}
header .grid-container.mega-menu-container {
	padding-right: 0;
    padding-left: 0;
}


/* Small & Medium only */
@media screen and (min-width: 0em) and (max-width: 63.9375em) {
	header > .grid-container,
	header.hcp > .grid-container,
	header > .grid-container:first-child {
		padding-right: 0.625rem;
    	padding-left: 0.625rem;
	}
	header .main-nav ul {
		float: none;
		text-align: left;
	}
}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 1115px) {
	header .main-nav .dropdown.menu > li > a, header .main-nav .dropdown.menu > li.is-dropdown-submenu-parent > a{
		//padding: 0.7rem 1.9rem;
		//padding: 0.7rem;
	}

	
}


/* ====================================================
    Header - mobile
   ==================================================== */

/* Small & Medium only */
@media screen and (min-width: 0em) and (max-width: 63.9375em) {
	header {
		.top-nav-area {
			.top-nav {
				.menu {
					justify-content:center;
					margin-bottom: 10px;
					//margin-top: 3px;
					text-align: center;

					a{
						display: inline-block;
					}
				}
				.accordion-menu{
					li{
						width:auto;
					}
				}
			}
		}
	}
	
	.main-nav-area {
		.mobile-nav {
			float: right;
			margin-right: 10px;
			margin-top: 2%;

			.menu-icon{
				width:40px;
				height:26px;

				&:after{
					height:4px;
					box-shadow: 0 9px 0 #FFFFFF, 0 19px 0 #FFFFFF;
				}
			}
		}



		.main-nav {
			background-color: $secondary-color;
			margin-left:0;
			margin-right: 0;
			width: 100%;
			margin-top: 15px;
			padding-top: 0;
			margin-bottom:-10px;
			font-size:20px;

			.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
				border-color: #fff transparent transparent;
			}

			.accordion-menu {
				a {
					padding: 1.2rem 1.2rem;
					border-bottom: 1px solid #fff;



				}

				.submenu {
					border-bottom: 1px solid #fff;
					a{
						padding: 0.8rem 1.2rem;
						border-bottom:none;
					}
				}
			}

			ul {
				text-align: left;

				ul li {
					background-color:#83AACC;
					text-transform: capitalize;
					font-size: 16px;

					a:hover, a.active {
						color:$primary-color;
					}


				}

			}

		}
	}

}


/* ====================================================
    Hero
   ==================================================== */

.hero {
	color:#fff;
	// padding-top: 156px;
	// margin-bottom: 40px;
	z-index: 1;
	position: relative;
	
	min-height: 625px;




	.hero-message{
		position: absolute;
		top: 29%;
		max-width:73.1rem;

		a{
			text-decoration: none;
			color: #fff;
		}

		.trans-box2{
			padding:20px 23px;
			font-size:16px;
			text-shadow: 0px 2px 12px rgba(0,0,0,0.94);

			p{
				line-height: 1.25em;
			}

			

			.trans-box-blue{
				background-color: rgba(0,69,128,0.65);
				padding:17px 25px 17px 25px;
				margin-left:-36px;
				font-family: 'Roboto Condensed', sans-serif;
				font-weight:700;
				text-transform: uppercase;
				height:100%;
				text-align: center;

				.hero-txt1{
					font-size:35px;
					color:#E4B600;
					line-height: 1.0em;
					margin-bottom: 4px;
				}

				.hero-txt2,.hero-txt2-1,.hero-txt2-2,.hero-txt2-3{
					display: inline-block;
				}
				.hero-txt2{
					position: relative;
					width: 425px;
					height:99px;

					.hero-txt2-1{
						font-size:36px;
						line-height: 1.0em;
						position: absolute;
						top: 0;
						left: 0;

					}
					.hero-txt2-2{
						font-size:45px;
						color:#E4B600;
						line-height: 1.0em;
						position: absolute;
						top: 28px;
						left: 27px;

						span{
							font-size:125px;
						}
					}
					.hero-txt2-3{
						font-size:31px;
						line-height: 1.0em;
						position: absolute;
						bottom: 0;
						left:119px;
					}

					.hero-txt2-4{
						font-size: 34px;
						line-height: 1.0em;
						position: absolute;
						top: 12px;
						right:0;
					}
				}
				
				
			}

			.button{
				font-size:16px;
				text-transform: inherit;
				//width:100%;
				text-align: left;
				text-shadow:none;
				position: relative;
				padding: 0.8em 3.2em 0.8em 1.2em;
				margin-bottom: 0;
				margin-top: 3px;
				box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.33);

				&:after {
			    	content: '\25b6 \fe0e';
			    	color: #ffffff;
			    	right:20px;
			    	position: absolute;
			    	top:35%;
			    }
			}
		}
		.header-title{
			text-align: center;
			font-family: 'Roboto Condensed', sans-serif;
			font-weight:700;
			font-size: 51px;
			text-transform: uppercase;
			line-height: 1.0em;
			margin-top: 30px;
			text-shadow: 0px 2px 12px rgba(0,0,0,0.94);
		}
	}

	.trans-box, .trans-box2{
		background-color: rgba(66,125,176, 0.80);
		margin-left:13px;
		
	}

	.trans-box {
		position: absolute;
		top: 32%;
		max-width: 672px;
		padding:11px 23px;
		text-shadow: 0px 3px 8px rgba(0,0,0,0.38);
		z-index: 1;

		a {
			color:#fff;
		}
	}

	h1 {
		font-weight:700;
		color:#fff;
		line-height: 1.1em;
		
		margin-bottom: 0.8rem;
		background-color: rgba(228,182,0,0.80);
		display: inline-block;
		padding:10px 25px 10px 36px;
		margin-left:-36px;

		span{
			font-size:44px;
			color: #fff;
		}

	}

	h4 {
		color:#fff;
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
		margin-bottom: 0.7rem;
	}

	p{
		margin-bottom: 0.8rem;
	}

	

	.footnote{
		text-shadow: 0px 2px 12px #000;
	}

	



	ul {
		color:#fff;
	}

	&.sub {
		margin-bottom:0px;
		// padding-top: 180px;
		min-height: 580px;
		background-repeat: no-repeat; 
		background-position: top center;

		&.sub-migraines{
			background-image: url('../img/header-migraines-dskt.png'); 			
		}
		&.sub-epilepsy{
			background-image: url('../img/header-epilepsy-dskt.png'); 
		}
		&.sub-coupon{
			background-image: url('../img/header-coupon-dskt.png'); 			
		}
		&.sub-contact{
			background-image: url('../img/header-contact-dskt.png'); 
		}
		&.sub-default{
			background-image: url('../img/header-default-dskt.png'); 
		}
	}

	.hero-img{
		position: absolute;
		right: 0;
		left: 0;
		margin-left: auto;
		margin-right: auto;
		z-index: 0;
		opacity: 0;
	}
}


.card-spc .hero {
	&.sub{
		min-height: 520px;
	}
	.trans-cont{
		position: relative;
	}
	.trans-box{
		max-width: 98.5%;
		width: 98.5%;
		margin-top: 158px;

		button{
			font-size:18px;
			width: 200px;
			box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.33);

			&[disabled],&.disabled{
				opacity: 1;
				background-color: #ccc;
			}
		}
	}
}

.contact-spc .hero .trans-box{
	font-size: 18px;

	h4{
		font-size: 25px;
	}
}

.other-spc .hero {
	.trans-box {
		padding-right:19px;
		top: 45%;

		h1 {
			margin-bottom: 0;
			width: 268px;
		}
	}
	&.sub{
		min-height: 447px;
	}
}







.video-embed {
	position: relative;
	height: auto;
	margin-bottom: 0rem;
	overflow: hidden;
	max-width: 92em;
    margin: 0 auto;

}
.video-embed video{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	height: 828px;

}
.video-embed .hero-img{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	height: auto;
}





/* ====================================================
    SWF video
   ==================================================== */
.responsive-embed, .flex-video {
	padding-bottom: 57%;
}

/* ====================================================
    Buttons
   ==================================================== */
.button {
    text-decoration: none;
    text-transform:uppercase;
    font-weight: 400;

    
}

 

/* ====================================================
    Reveal
   ==================================================== */
.reveal {
	padding: 3rem;
}

.reveal.large {
	max-width: 50em;
}


/* ====================================================
    Form
   ==================================================== */
form{

	table{
		margin-bottom: 0;

		tbody{
			border:none;
			background-color:inherit;

			td{
				padding:0;
			}
		}
	}

	/* Customize the label (the container) */
	.check-container {
	  display: block;
	  position: relative;
	  padding-left: 45px;
	  font-size:16px;
	  // margin-top:-2px;
	  cursor: pointer;
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	}

	/* Hide the browser's default checkbox */
	.check-container input {
	  position: absolute;
	  opacity: 0;
	  cursor: pointer;
	  height: 0;
	  width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
	  position: absolute;
	  top: 2px;
	  left: 0;
	  height: 32px;
	  width: 32px;
	  background-color: #fff;
	  border: 1px solid #C7C7C7;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.check-checkmark:after {
	  content: "";
	  position: absolute;
	  display: none;
	}

	/* Show the checkmark when checked */
	.check-container input:checked ~ .checkmark:after {
		display: block;
		content:'X';
		color:$secondary-color;
		font-size: 24px;
		font-weight: 700;
		margin-left: 7px;
		text-shadow:none;
		margin-top: -5px;
	}


}

/* ====================================================
    ISI Section
   ==================================================== */
.isi-section {
	background-color: rgba(255,255,255,0.95);
	box-shadow: 0px 3px 39px rgba(0,0,0,0.16);
	padding-top: 30px;
	padding-bottom: 30px;

	h5{
		font-size: 25px;
	}
	h6{
		font-size:18px;
	}
}

#sticky-isi { 
    position: fixed; 
    height:25%;
    z-index:1000;
    overflow-y: hidden;
    bottom: 0;
    right:0px;
    left:0px;
    //width: 78em;
    max-width: 100%;
    margin: 0 auto;
    &.isi-section {
    	padding-top: 10px;

    	.sIND {
    		position:relative;

    		.isi-btn {
	    		position:absolute;
	    		right:0;
	    		text-decoration: none;
	    		color: $primary-color;
	    		font-size: 28px;
	    	}
    	}

    	&.mob_isi{  
        	max-height:75%;
        	height:auto;
        	padding-bottom: 0;
        	max-width: auto;
    		margin: auto;

        	h5{
        		margin-bottom: 0;
        	}

        	.content_box {
	          padding-top: 10px;
	          height: 75px;
	          
	        }

	        .s_header{
	            font-size: 1.1em;
	            position:relative;

	            &:after{
	            content: "+";
	            font-size: 1.5em;
	            position: absolute;
	            right: 2px;
	            top:0;
	            color: $primary-color;
	            }
	            &.isiOn:after  {
	               content:"-" ;
	            }  
	                
	         }

	        .sISI{ 
	        	overflow-y: hidden;
	        	border-top: 1px solid #999;
	        	padding-top: 5px;
	        	

	            &.On{
	                height:70%;
	                overflow-y: hidden;
	                
	                .content_box{
	                    height:40vh;
	                    overflow-y: auto;
	                }
	            }
	        }

	        .sIND{
	        	overflow-y: hidden;
	        	
	        	margin-bottom: 10px;

	            &.On{
	                height:79%;
	                overflow-y: hidden;
	                
	                .content_box{
	                    height:27vh;
	                    overflow-y: auto;
	                }
	            }
	        }


        }
    }
}


.page-ISI {
	 h5 {
	 	display: none;
	 }
	 h6{
	 	line-height: 1.1em;
		font-size: 24px;

		sup{
			top: -0.5em;
		}
	 }
}

/* ====================================================
    Footer Section
   ==================================================== */

footer {
	font-size: 13px;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #E4ECF8;

	ul {
		list-style:none;
		margin:0;

		li{
			display: inline-block;
			border-right: 1px solid $black;
			padding-right: 10px;
			padding-left: 5px;

			&:first-child{
				padding-left: 0;
			}
			&:last-child{
				padding-right: 0;
				border-right: none;
			}
		}
	}

}


/* ====================================================
    Responsive Styling
   ==================================================== */

/* Small only */
@media screen and (max-width: 39.9375em) {
	body{
		font-size:15px;
	}
	.container-spacing {
    	padding-top: 30px;
    	padding-bottom: 30px;
	}
	
	.other-spc {
		.hero {
			&.sub {
			    min-height: 300px;
			    // background-image: url('../img/header-other-mbl.png');


			}

			.trans-box{
				max-width: 251px;

				h1{
					width:248px;
					padding-right: 15px;
    				padding-left: 30px;
				}
			}
		}
	}

	#sticky-isi{
		p, li, ul {
			margin-bottom: 0.5rem;
		}
	}

	#sticky-isi.isi-section {
	    padding-top: 5px;
	}

	.isi-section {
		h5 {
			font-size:18px;
		}
		h6 {
			font-size:16px;
		}
	}

	.logo {
		width: 120px;
	}

	.menu.align-center li {
		margin-bottom: 5px;
	}

	header{
		.top-nav-area {
		    font-size: 12px;
		}
	}

	.hero-home{
		background-color: #000;
	}


	.contact-spc .hero .trans-box h4 {
	    font-size: 20px;
	}
	
	.hero {
		min-height: 400px;

		h1 {
			padding: 10px 17px 10px 25px;
			font-size:25px;
			margin-bottom: 0.6rem;
			margin-left: -27px;

			span{
				font-size: 29px;
			}
		}

		h4{
			font-size: 19px;
		}
		&.sub{
			min-height: 350px;
			// background-image: url('../img/header-about-mbl.png');
			margin-bottom: -10px;

				&.sub-migraines{
					background-image: url('../img/header-migraines-mbl.png'); 			
				}
				&.sub-epilepsy{
					background-image: url('../img/header-epilepsy-mbl.png'); 
				}
				&.sub-coupon{
					background-image: url('../img/header-coupon-mbl.png'); 			
				}
				&.sub-contact{
					background-image: url('../img/header-contact-mbl.png'); 
				}
				&.sub-default{
					background-image: url('../img/header-default-mbl.png'); 
				}
		}

		.hero-message{
			margin-top:33%;
			bottom: inherit;
			position: inherit;

			.trans-box2 {
				padding: 15px 13px 15px 13px;
				font-size: 15px;

				.button{
					//font-size:17px;
					margin-top: 0;
				}

				p{
					margin-top: 10px;
				}

				.trans-box-blue {
					padding: 13px 20px 10px 35px;
					margin-left: -25px;

					p{
						margin-top: 0;
					}

					.hero-txt1{
						font-size:22px;
					}
					.hero-txt2 {
						width:100%;
						height: 64px;

						.hero-txt2-1 {
							font-size:23px;
						}
						.hero-txt2-2{
							font-size:29px;
							top: 19px;
	   						left: 15px;

							span {
								font-size: 80px;
							}
						}
						.hero-txt2-3{
							font-size:19px;
							left: 75px;
						}
						.hero-txt2-4{
							font-size:21px;
						}
					}
				}
			}
		}

		.trans-box{
			// max-width: 91%;
			margin-top:35%;
			bottom: inherit;
			position: inherit;
			padding: 11px 18px;
			margin-left:10px;
			
		}
	}

	.card-spc .hero{
		.trans-cont{
			position: inherit;
		}
		.trans-box{
			width: auto;
			margin-top: 35%;
		}
	}


}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
	.card-spc .hero{
		.trans-cont{
			position: inherit;
		}
		.trans-box{
			width: auto;
			margin-top: 0px;
		}
	}
}



 /**** Mega menu ***/
 .mega-menu {
 	//margin-top: 10px;
  .dropdown-pane {
    width: 100%;
    max-width: 85rem;
    margin: 0 auto !important;
    left: 0% !important;
    right: 0% !important;
    background-color: #427DB0;
    border: 0 !important;
    top:81px !important;
    ul li {
    	text-transform: none;
    }
  }

  .menu {
    background: none;
  }

  > a::after {
    /*display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    margin-left: 10px;
    margin-top: 11%;*/
  }
  a.menu-parent {

  }

.dropdown-pane{
  	&::after {
	content: "";
    display: block;
    position: absolute;
    top: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent #427DB0 transparent;
  	}
  }

	&.menu-migraine .dropdown-pane::after {
		left: 460px;
	}
	&.menu-epilepsy .dropdown-pane::after {
		left: 664px;
	}

}



.top-bar {
	padding: 0;
}
.top-bar, .top-bar ul {
	background: none;
	text-transform: uppercase;
	text-align: center;
	font-size:19px;
	li {
		a {
			padding: 0 !important;
		}
		.dropdown-pane {
			ul.menu li {
				a {
					padding: 0rem 1rem !important;
					font-size:16px;
				}
			}
		}
	}
	&.menu {
		.menu-text {
    		// padding: 0!important;
    		width: calc(33.33333% - 1.875rem);
			text-align: left;

    		a {
    			padding: 0 2.8rem 0 2.2rem !important;
    			@media screen and (min-width: 1024px) and (max-width: 1166px) {
			    	//padding-right: 0.225rem !important;
			    	padding-left: 0.225rem !important;
			    }
    			img.logo {
    				//max-width: 150px;
    			}
    		}

    	}

    	&.dropdown {
    		li.patient-menu  {
    			width: calc(19.67% - 1.875rem);
    			a {
	    			margin-top: 25px;

		    		@media screen and (min-width: 1024px) and (max-width: 1166px) {
		    			//font-size: 17px;
		    			padding: 0.7rem 2.4rem !important;

		    		}
		    		ul li a {
		    			@media screen and (min-width: 1024px) and (max-width: 1166px) {
		    				font-size: 16px;
		    			}
		    		}
	    		}
	    		ul li a {
	    			margin-top: 0;
	    		}
    		}	
    	}
    	
	}
}


